<template>
  <div id="main">
    <div>
      <p>Kod za proveru statusa</p>
      <h1>{{ $route.params.code }}</h1>
      <p>
        (Ukucajte ovaj kod u polje na linku: <strong>https://app-sistemcd.braniweb.com</strong> i kliknite na link "PROVERA STANJA".
        Ukucajte ovaj kod i dobićete informaciju da li su proizvodi po Vašem nalogu završeni.)
      </p>
      <p>-----</p>
      <p>{{ $route.params.company }} | {{ $route.params.contactPerson }}</p>
      <p>{{ $route.params.phone }}</p>

      <p>Proizvodi:</p>
      <v-simple-table class="text-left pa-1">
        <tr class="font-weight-bold">
          <td>Šifra</td>
          <td>Naziv</td>
          <td>Količina</td>
          <td>Cena</td>
        </tr>
        <tr
          v-for="(product, i) in $route.params.products"
          :key="i"
        >
          <td>{{ product.code }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.quantity }}</td>
          <td>{{ ((+(product.price.replace(",", "")) * 1.2).toFixed(2) * product.quantity).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} RSD</td>
        </tr>
      </v-simple-table>
      <p class="pt-2">
        Web: https://sistemcd.rs |
        E-mail: office@sistemcd.rs |
        Tel. 011/2457-666
      </p>
    </div>
    <div id="toolbar">
      <v-btn
        class="mb-3"
        block
        small
        outlined
        @click="print()"
      >
        {{ $t('Print') }}
      </v-btn>
      <v-btn
        class="mb-3"
        block
        small
        outlined
        @click="back()"
      >
        {{ $t('Close') }}
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WarrantCheckcode',
  data() {
    return {}
  },
  methods: {
    print() {
      window.print()
    },
    back() {
      window.history.back()
    },
  },

}
</script>
<style>
  #main {
    width: 620px;
    margin: 0 auto;
    font-size: 80%;
    text-align: center;
  }
  .border-bottom-2px-silver {
    padding: 0.1em !important;
    margin: 0.1em !important;
  }
  @media print {
    #toolbar {
      display: none;
    }
  }
</style>
